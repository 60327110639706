$(document).keyup(function (e) {
  if (e.keyCode == 27) {
    $('.hamburger').removeClass('is-active');
    $('#main-wrapper, .overlay').removeClass('off-canvas-active');
    $('#off-canvas').removeClass('active');
    $('body').removeClass(
      'no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas'
    );
    $('.popup').removeClass('active');
  }
});

$('.overlay').click(function () {
  $('.hamburger').removeClass('is-active');
  $('#main-wrapper, .overlay').removeClass('off-canvas-active');
  $('#off-canvas').removeClass('active');
  $('body').removeClass(
    'no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas'
  );
  $('.popup').removeClass('active');
});

$('.header-mainnav').mouseout(function () {
  $('.overlay').removeClass('menu-active');
});

$('.off-canvas-toggle').click(function () {
  $('.hamburger').toggleClass('is-active');
  $('#main-wrapper, .overlay').toggleClass('off-canvas-active');
  $('#off-canvas').toggleClass('active');
  $('body').toggleClass('no-scroll-off-canvas');

  var offCanvasHeight = $('.off-canvas-wrapper').height();
  $('.off-canvas-wrapper').css('height', offCanvasHeight);
});

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

$(function () {
  $('.scroll-down').click(function () {
    $('html, body').animate({ scrollTop: $('div.first').offset().top }, 'slow');
    return false;
  });
});
$(function () {
  var header = $('#header-main, #off-canvas');
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 28) {
      header.addClass('sticky');
    } else {
      header.removeClass('sticky');
    }
  });
});
$('.header-mainnav > ul > li:has(ul)').addClass('has-subnav');

// Slider Start

$('.slider').slickLightbox({
  itemSelector: '> div > div > div > a',
});

$('.slider').slick({
  infinite: true,
  autoplay: false,
  dots: false,
  pauseOnHover: true,
  speed: 1000,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: true,
});

// LogoSlider Start

$('.logoslider').slick({
  infinite: true,
  autoplay: true,
  dots: false,
  arrows: false,
  pauseOnHover: false,
  variableWidth: true,
  cssEase: 'linear',
  speed: 500,
  centerMode: true,
});

// calculator
$(function () {
  $('.accordion__title').on('click', function (e) {
    e.preventDefault();
    var $this = $(this);

    if (!$this.hasClass('accordion-active')) {
      $('.accordion__content').slideUp(400);
      $('.accordion__title').removeClass('accordion-active');
      $('.accordion__arrow').removeClass('accordion__rotate');
    }

    $this.toggleClass('accordion-active');
    $this.next().slideToggle();
    $('.accordion__arrow', this).toggleClass('accordion__rotate');
  });
});

$(function setMargin() {
  var titleblock = $('.titleblock .content-wrapper .grid-wrapper > div');
  var height = titleblock.height();
  var marginBottom = height / 2;

  if (!$('.titleblock').hasClass('start')) {
    $('.titleblock .content-wrapper').css(
      'margin-bottom',
      '-' + marginBottom + 'px'
    );
  }
});

setMargin();
